.search {
 

  div.autocomplete {
    .autocomplete-input {
      input {
        text-align: left;
        padding: 0 0 1rem 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.38);
        width: 95%;
      }
    }
  }

  .admin-access{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: 100px;

    .switch-field{
      width: 50%;
      display: flex;
      justify-content: space-evenly;
    }

    .select-input {
      width: 50%;
    }

  }

  .form-error {
    padding: 0;
    text-align: left;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .filters {
    display: flex;
    align-items: center;
    margin-top: 3rem;

    #account-filter-label {
      margin: 0 1rem 0 2rem;
    }

    .select-status {
      width: 10%;
    }

    div {
      width: 80%;

      .container_search {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(#ffb81d, 0.2);
        width: 100%;

        .MuiTextField-root {
          width: 80%;
          border-radius: 5px;
          margin: 10px;

          input {
            padding: 6px 19px 7px;
          }

        }

        .mdi {
          font-size: 2rem;
          margin: 0.5rem;
        }

      }
    }

  }

  margin: 1rem;
  width: auto;
  overflow: hidden;

  .device-info {
    display: flex;
    justify-content:space-between;

    .version-logo {
      display: flex;
      align-items: center;
      width: 50%;
      margin-right: 0;
    }
    .contact-user{
      width: 50%;
      margin-left: 2rem;
      .container_phone{
        margin-left: 0;
        display: flex;
        align-items: center;
        .container_phone_buttons{
          margin-left: 1rem;
        }
        .phone_input_component{
          .react-tel-input{
            width: 100%;
            .form-control {
              width: 100%;
              padding-left: 50px;
            }
          }
        }
      }
  
    }
    @media (max-width: 768px) {
      .contact-user{
        .container_phone {
          display: block; 
          .container_phone_buttons{
          justify-content: flex-end;
        }
        }
      }
      }
  }

 
  .MuiInputBase-fullWidth {
    padding: 15px;
  }

  .MuiFormControl-root {
    margin-bottom: 2rem;
  }

  .modal {
    .form {
      margin: 0;

      .span-title {
        font-weight: bold;
      }

      .accounts_list {
        display: flex;
      }

    }

    .accounts_list {
      margin-top: 50px;

    }

    .MuiTableContainer-root {
      font-family: sans-serif;
      margin-bottom: 50px;
    }

    .info-app {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .MuiTextField-root {
        justify-content: flex-start;
      }
    }
  }
  .title-accounts{
    margin-top: 2rem;
    color: rgba(0, 0, 0, 0.38);
  }
  .MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 0 16px 5px 16px;
  }

  .title-organization {
    color: rgba(0, 0, 0, 0.38);
    padding-top: 0;
    margin-top: 0;
  }
}

.container_buttons-principal {
  cursor: pointer;
}