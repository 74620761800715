
.table-root {
    width: 100%;
  }
  
  .table-container {
    max-height: 440px;
  }
  
  .table-header {
    font-weight: 800 !important;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  
  .fondoVerde {
    color: white;
    background-color: #5a9c24;
    border-radius: 20px;
    display: inline-block;
    padding: 5px 15px;

  }
  
  .fondoRojo {
    color: white;
    background-color: #f50057;
    border-radius: 20px;
    display: inline-block;
    padding: 5px 15px;

  }
  .table-row {
    // display: flex;
    justify-content: center;
  }
  .table-cell:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
  }
  .container-filter {
    display: flex;
    justify-content: center;
  }

  .master-account-row {
    background-color: #62cdca;
  }

  .master-account-row:hover {
    cursor: pointer;
    background-color: rgb(94, 197, 193);
  }