.content{
    display: block;
    width: 90%;
    height: 100%;
    padding: 1%;
    margin: auto;
    .form_container{
        .container{
            width: 100%;    
            margin-bottom: 10%;   
        }
        .form_master_account {
            display: flex;
            justify-content: space-between;
            .form_check_master {
                width: 48%;
            }
            .form_slave_account {
                width: 48%;
            }
        }
    }
}
.container-btns {
    display: flex;
    align-items: center; 
    justify-content: space-between;
}            

.container-btns > * {
    margin-right: 10px; 
  }
  
  .container-btns > *:last-child {
    margin-right: 0; 
  }