.container-btns {
    display: flex;
    justify-content: center; 
}
.container-headers{
    height: 3rem;
    flex-grow: 1;
    margin-top: 20px;
}

.container-selec {
    margin-top: 2px;
}
.container-selec-for {
    margin-top: 10px;
    margin-bottom: 10px;
}

.container-switch label   {
    font-size: 13px;
}
.container-switch {
    font-size: 13px; 
}

.container-inp {
    margin-top: 5px;
}


