.photo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 150px;
  }
  input[type='file'] {
    display: none;
  }

  .MuiButton-contained {
    margin-top: 2rem;
    width: 270px;
    display: flex;
    label {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    i {
      width: 20%;
      font-size: 20px;
      display: block;
      margin-top: -5px;
    }
  }
  .button-container{
      display: flex;
      button{
          width: auto;
          margin: 5rem 1rem;
      }
      .btn-guardar{
        background: #ffb81d;
      }
  }
}
