


.container_restart {
    margin-left: 5vh;
    .buttonRestart {
        background-color: rgb(222, 222, 222);
        padding: 1vh;
    
        .icon-history {
            margin-right: 5px;
        }
    }


}
.modalConfirm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    padding: 6vh;
    background-color: white; 
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.5); 
    text-align: center;
    .error-message{
      color: red;
      font-size: 14px;
     }
    .description {
      font-size: 15px;
      text-align: center;
    }

    .containerButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 5vh;

      .cancelButton {
        background-color: rgb(222, 222, 222);
        padding: 1vh;
      }

      .restoreButton {
        background-color: #FFB81E;
        padding: 1vh;
      }
    }
  }