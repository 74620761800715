.container-info-details {
    .container-json-metadata {
        display: flex;
        .jsonDetails {
            width: 35%;
            margin-top: 4vh;
            max-height: 80vh;
            overflow-y: auto;
        }
        .container-metadata {
            width: 40%;

            h1 {
                font-size: 18px;
            }

            .list-metadata {
                list-style: none;
                padding: 0;
                border: 1px solid rgb(222, 213, 213);
                border-radius: 10px;

                .li-metadata {
                    border-bottom: 1px solid rgb(222, 213, 213);
                }

                li,
                .li-metadata {
                    padding: 2vh;
                    margin: 0 2vh;
                }
            }

            .button_return {
                padding: 1vh;
                background: rgb(222, 222, 222);
                border: rgb(222, 222, 222);
            }
        }

        




    }
}