$gray: #808080;

.select-input-option{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.container-selected {
    direction: row;
    flex-wrap: wrap;
}
// .container-selected:hover {
//     border-bottom: 1px solid transparent;
// }

.options-value {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    justify-self: center;
    padding: 10px 0 10px;
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover{
        background-color: rgba(0, 0, 0, 0.04);
    }
}

.menu-item-select {
    display:flex;
    align-items: center;
    justify-content: center; 
    height: 100%;
    text-align: center;
}

.menu-item-select div {
    text-align: center;
    width: 100%;
}

.search .MuiFormControl-root {
    margin-bottom: 1rem;
}

.css-1dy7cw-MuiInputBase-root-MuiInput-root:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
    padding: 0 0 10px 0;
}

.css-16awh2u-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
    padding: 4px 4px 30px 0px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-zrf9s3  {
    border-bottom: 1px solid gray;
}