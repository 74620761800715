.table-container {
    position: relative;
    overflow-x: auto;
    max-width: 100%;
    height: 100%;

    .scrollableCell {
        position: relative;
        z-index: 0;
    }

    .fixedCell {
        position: sticky;
        right: 0;
        z-index: 1;
    }

    .cell {
        background: #f2f1f1
    }

}