.side-menu {

  box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2),
    0px 2px 30px 0 rgba(0, 0, 0, 0.35);
  background-color: #ffb81d;
  overflow-x: hidden;
  width: 70px;
  height: 100%;
  transition: width 0.4s;
  z-index: 1000;
  &:hover {
    width: 350px;
    transition: width 0.3s;

    .side-menu__user-info,
    .user-menu {
      align-self: center;
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  &__container {
    padding: 0 1rem;
  }

  .bm-burger-button {
    display: none;
  }

  .bm-menu-wrap {
    background-color: #ffb81d;
  }

  &__brand,
  &__user {
    padding: 3rem 0;
    border-bottom: 1px solid rgba(#fff, 0.4);
  }

  &__brand {
    text-align: center;

    img {
      width: 25px;
    }
  }

  &__user {
    padding: 1rem 0;
  }

  &__user-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .mdi-account-circle-outline {
      font-size: 1.5rem;
      padding-left: 0.7rem;
      margin-right: 6px;
    }
  }

  &__user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__user-display {
    > div:first-child {
      font-size: rem-calc(16);
    }
    strong {
      font-weight: bold;
    }
  }

  &__user-info,
  .user-menu {
    font-weight: 300;
    margin-left: 0.5rem;
    font-size: rem-calc(14);
  }

  .user-menu {
    li {
      list-style: none;
      padding: 0;
      margin: 1rem 0;
      display: flex;
      align-items: center;
    }

    a {
      text-decoration: none;
      color: #2c2c2c;
      font-size: rem-calc(14);
    }
  }

  .side-menu-list {
    padding: 0;
    margin: 1.5rem 0;
    li {
      list-style: none;
      font-size: 1.5rem;
    }
    .mdi {
      margin: 5px;
    }
    .mdi-image{
      height: 30px;
    }
    a {
      display: flex;
      align-items: center;
      color: #2c2c2c;
      padding: rem-calc(15) 1.25rem;
      text-decoration: none;
      span {
        margin-left: 1.5rem;
      }
    }
  }

  a.item--selected {
    background-color: #fff;
    color: #2c2c2c;
    border-radius: 30px;
    .menu-icon {
      color: inherit;
    }
  }
}
