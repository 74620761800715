.home {
  padding: 2rem 2rem;
  height: 90%;
  display: flex;
  flex-wrap: wrap;

  .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 10rem;
    width: 10rem;
    border: 5px solid black;
    border-radius: 1rem;
    margin: 1rem;
    i {
      font-size: 7rem;
      color: black;
    }
    h4 {
      margin-top: -1rem;
      color: black;
    }
    .mdi-image{
      height: 7rem;
      margin-bottom: 1rem;

    }
  }
}
