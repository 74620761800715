:root {
    --button-height: 35px;
    --button-padding: 0 20px;
}

.container-explorador-backup {
    .MuiAlert-filled{
        color: white;
        background-color: #039ae57d;
    }
    .alertError{
      background-color: rgba(255, 0, 0, 0.553);
    }
    p{
        color: rgb(97, 97, 97);
    }
    form {
        border: 1px solid rgb(222, 213, 213);
        padding: 16px 16px;
        border-radius: 5px;
        margin: 2vh 0;
        display: flex;
        align-items: center;
        
        .MuiInput-underline:before,
        .MuiInput-underline:after {
            content: none;
        }

        p {
            margin: 0 2vh;
            color: rgb(97, 97, 97);
        }
        .MuiInputBase-input {
            border: 1px solid rgb(222, 213, 213);
            padding: 16px 16px;
            border-radius: 5px;
            width: 50%;
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;

        .button_return {
            padding: 1vh;
            background: rgb(222, 222, 222);
            border: rgb(222, 222, 222);
        }
    }

    .error-message {
        color: red;
        margin-left: 8vh;
        margin-top: 1vh;
    }

    .search {
        border: 1px solid rgb(222, 213, 213);
        padding: 16px 16px;
        border-radius: 5px;
        margin: 2vh 0;

        .container_search {
            display: flex;
            align-items: center;
        }


        .mdi-magnify {
            padding-right: 16px;
            font-size: 25px;
        }

        .MuiInputBase-root {
            width: 50%;

            .MuiInputBase-input {
                padding: 10px;
                border: 1px solid rgb(222, 213, 213);
                border-radius: 5px;
            }
        }
    }

    &.expanded {

        .container-table-json {
            display: flex;

            .container-table-pagination {
                width: 100vh;
            }

            .container-json {
                height: 85vh;
                width: 50vh;
                overflow-y: auto;
                margin-left: 2vh;

                .modal {
                    .buttonMore {
                        background: #ffb81d;
                        border-radius: 3px;
                        color: #000000;
                        height: 48px;
                        padding: 0 30px;
                        margin-top: 10px;
                        justify-content: center;
                        margin-left: 2vh;
                    }

                    .buttonCancel {
                        border-radius: 3px;
                        height: 48px;
                        padding: 0 30px;
                        margin-top: 10px;
                        justify-content: center;
                    }
                }

                .buttons-json {
                    display: flex;
                    justify-content: center;
                }
            }


            @media (max-width: 1300px) {
                .container-table-pagination {
                    width: 70vh;
                }

                .container-json {
                    .modal {

                        .buttonMore,
                        .buttonCancel {
                            height: var(--button-height);
                            padding: var(--button-padding);
                        }
                    }

                }

            }

            @media (max-width: 1100px) {
                .container-table-pagination {
                    width: 50vh;
                }

                .container-json {
                    .modal {

                        .buttonMore,
                        .buttonCancel {
                            height: var(--button-height);
                            padding: var(--button-padding);
                        }
                    }

                }
            }

            @media (max-width: 900px) {
                .container-table-pagination {
                    width: 30vh;
                }

                .container-json {
                    .modal {

                        .buttonMore,
                        .buttonCancel {
                            height: var(--button-height);
                            padding: var(--button-padding);
                        }
                    }

                }
            }

            @media (max-width: 500px) {
                display: flex;
                flex-direction: column;

                .container-table-pagination {
                    width: 100%;
                }

                .container-json {
                    width: 100%;

                    .modal {

                        .buttonMore,
                        .buttonCancel {
                            height: 35px;
                            padding: 0 20px;
                        }
                    }

                }
            }


        }
    }

    .container-table-pagination {
        width: 155vh;

        @media (max-width: 1500px) {
            width: 133vh;
        }

        @media (max-width: 1320px) {
            width: 125vh;
        }

        @media (max-width: 1240px) {

            width: 115vh;
        }

        @media (max-width: 1100px) {
            width: 100%;
        }


    }

    .MuiPaper-root {
        .MuiTable-root {
            border-collapse: collapse;
            border: 1px solid rgb(222, 213, 213);
            margin: auto;
            width: 100%;

            .MuiTableHead-root,
            th {
                background-color: rgb(241, 241, 241);
                font-weight: bold;
            }

            th,
            td {
                border: 1px solid rgb(222, 213, 213);
            }

            .MuiTableBody-root {
                .MuiButton-root {
                    width: 10vh;
                    color: black;
                }
            }

        }

    }

}