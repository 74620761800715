.organizations{
      .container_header {
        height: 3rem;
        .container_search {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: rgba(#ffb81d, 0.2);
          width: 100%;
          .MuiInputBase-root {
            width: 80%;
            border-radius: 5px;
            input {
              padding: 6px 19px 7px;
            }
          }
          .mdi {
            font-size: 2rem;
            margin: 0.5rem;
          }
        }
      }

    margin: 1rem;
    width: auto;
    height: 90%;
    
    .MuiListItem-button{
        border-radius: 5px;
        margin: 6px;
        border: 1px solid rgba(179,179,179,0.7);
        box-shadow: 2px 2px  rgba(147,172,189,0.8);
    }
    .container_buttons-principal{
        cursor: pointer;
        .mdi{
          font-size: 1.2rem;
          margin: 0.5rem;
        }
    }   
    
  }

