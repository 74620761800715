.MuiInputLabel-root {
    text-align: left;
  }

  .MuiSelect-select{
    text-align: left;
  }

  .modal .MuiDialog-paperScrollPaper .popup-body p {
    font-size: 0.8rem;
}