.phone_component{
  margin-top: 40px;
  .phone_input_component{
    width: 100%;
  }  
  .phone_input_component{

      .form-control {
      padding-left: 30px;
      }

  }
  .phone-buttons{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 5%;

    .MuiButton-root{
      margin-right: 5px;
      margin-top: 5px;
  
      .MuiButton-startIcon{
        margin: 0;
        
      }
      
    }
  }
  margin-bottom: 30px;

  
}