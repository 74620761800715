.root {
    display: inline-block;
    margin-right: 10px;
  }
  .label{
    margin-right: 16px;
  }

  .select{
    margin-right: 16px;
  }
  .containerFilter {
    margin-right: 16px;
  }
.container-filter{
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.container-header {
  height: 3rem;
  flex-grow: 1;
  .container_search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 4px;
    background-color: rgba(#ffb81d, 0.2);
    .MuiInputBase-root {
      width: 80%;
      border-radius: 5px;
      input {
        padding: 6px 19px 7px;
      }
    }
    .mdi {
      font-size: 2rem;
      margin: 0.5rem;
    }
  }
}
.container-icon-world{
  display: flex;
  justify-content: center;
  margin-right: 10px;
}
.container-icon-down{
  display: flex;
  justify-content: center;
  margin-left: 10px;
}
.container-input {
  display: flex;
  align-items: center;
  text-align: center;
}
.container-btn-public {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.container-list-menu {
  display:flex;
  align-items: center;
  text-align: center;
  margin: 5px 0 5px 0;
  text-align: center;
}
.container-menuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 20px;
  margin-top: 45px;
}
