.container-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #333;
    font-size: 24px;
  }
  
  .container-not-found p {
    margin-bottom: 16px;
  }
  
  .back-text {
    margin-top: 16px;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;   
    &:hover {
      background-color: #e0e0e0; 
    }
  }