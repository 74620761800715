@import '~@mdi/font/scss/materialdesignicons';
@import '../../core/components/SideMenu/AppSideMenu';

.dashboard {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .dashboard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }
  .container_buttons-principal {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    width: 30%;
    font-size: 20px;
    display: flex;
    .mdi {
      font-size: 1.25rem;
      margin-left: 10px;
    }
  }
}

.header {
  background-color: #ffb81d;
  padding: 0 1rem;
  min-height: 80px;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    margin-left: 3rem;
  }
}


