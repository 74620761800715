.modal {
  margin: auto;
  width: 35rem;
  .MuiDialog-paperWidthSm {
    width: 90%;
  }
  .MuiDialog-paperScrollPaper {
    display: flex;
    flex-direction: column;
    scrollbar-width: 1px;
    color:black;
    
    .popup-body {
      height: 100%;
      text-align: center;
      scrollbar-width: 1px;
      
      p {
        font-size: 1.5rem;
      }
    }
    
    .MuiIconButton-sizeSmall {
      font-size: 1.9rem;
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .button-confirm-container {
      display: flex;
      button {
        width: auto;
  
        margin: 2rem auto;
      }
      .btn-guardar {
        background: #ffb81d;
      }
  }
  
  }
  .error {
    width: 80%;
    margin: auto;
    padding: 5px 9px;
    border: 1px solid red;
    background-color: rgba(red, 0.2);
  }
  form {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 80%;
    label {
      margin-top: 10px;
    }
    input {
      height: 1.5rem;
    }
    #checkbox-group{
      margin: 5px;
    }
    .options-tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      label {
        display: flex;
        margin: 6px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
