.notes{
    display: flex;
    flex-direction: column;
    span {
    padding: 0.8rem 0 0.8rem 0;
    color: gray;
    text-align: left;
    font-size: 14px;
    font-family: roboto,"Noto Sans Myanmar UI",arial,sans-serif;
    line-height: 1.42;    
    }

}